import { Flex } from "components";
import { H2 } from "components/Typography";
import styled from "styled-components";

export const HourBox = styled(Flex)<{
  width?: string;
  height?: string;
  isActive?: boolean;
}>`
  cursor: pointer;
  width: ${({ width }) => width || "50px"};
  height: ${({ height }) => height || "25px"};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.neutral.white};
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.neutral.white};
    `};
`;

export const Label = styled(H2)<{ height?: string }>`
  line-height: 33px;
  height: ${({ height }) => height || "50px"};
  margin: 0.5rem;
  // center text vertically
`;
