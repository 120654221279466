import { Flex } from "components/Flex";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface ITabMenu {
  tabs: { id: number; name: string }[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

const Wrapper = styled(Flex)`
  width: 100%;
  flex-flow: row nowrap;
  gap: 20px;
  ${({ theme }) => `border-bottom: 1px solid ${theme.palette.neutral.grey}; `}
`;

const Tab = styled(Flex)<{ active: boolean }>`
  min-width: 100px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0px;

  ${({ active, theme }) =>
    active &&
    `color:${theme.palette.primary.main}; border-bottom: 2px solid ${theme.palette.primary.main};`};
`;

export const TabMenu = ({ tabs, selectedTab, setSelectedTab }: ITabMenu) => {
  const handleSelectTab = (id: number) => {
    if (id !== selectedTab) {
      setSelectedTab(id);
    }
  };
  return (
    <Wrapper>
      {tabs.map((e) => (
        <Tab
          key={e.id}
          active={e.id === selectedTab}
          onClick={() => handleSelectTab(e.id)}
        >
          {e.name}
        </Tab>
      ))}
    </Wrapper>
  );
};
