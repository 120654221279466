import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IUser } from "types/user";

export interface IUserState {
  me: IUser;
  friends?: IUser[]
}

const initialState: IUserState = {
  me: {
    firstName: "-",
    lastName: "-",
    username: "-",
    email: "-",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getMe: (state, { payload }) => {
      state.me = payload;
    },
  }
});

export const userData = (state: RootState) => state.user;

export const { getMe } = userSlice.actions;

export default userSlice.reducer;
