const common = {
  privacyPolicy: "privacy policy",
  regulamin: "regulamin",
  first_name: "first name",
  last_name: "last name",
  somethingWentWrong: "Something went wrong",
};

const auth = {
  login: "login",
  register: "register",
  password: "password",
  forgotPass: "Forgot password?",
  accountless: "Don't have account?",
  emailRequired: "Email is required",
  badEmailFormat: "Invalid email format",
  passwordRequired: "Password is required",
  passMinVal: "The password should contain at least 6 characters",
  passMaxVal: "The password can be up to 18 characters long",
  passDiffErr: "The passwords do not match",
  acceptRegulamin: "Accept the terms and conditions",
  password_confirmation: "password confirmation",
  send_email: "Send e-mail",
};

const buttons = {
  register: "Sing up",
  login: "Sing in",
  logout: "Log out",
  remindPassword: "Remind password",
  visitDetails: "Visit details",
  sheduleAnAppointmentAgain: "Shedule appointment again",
};

const form = {
  email: {
    label: "Email:",
    placeholder: "email@email.com",
  },
  password: {
    label: "Password:",
    placeholder: "********",
  },
};

const loginView = {
  login: "Login",
  rememberMe: "Remember me",
  remindPassword: "Remind password",
  accountless: "Don't have account?",
  sheduleAnAppointment: "Shedule an appointment",
};

const resetPassword = {
  forgotMyPassword: "I forgot my password hasła",
  writeEmail:
    "Enter the email address associated with your account and we'll send you a link to reset your password.",
  checkYourMailbox: "Check your mailbox",
  linkToSet:
    "A link to set a new password has been sent to the e-mail address provided.",
};

const registerView = {
  registr: "Register",
  accountAllready: "Already have an account?",
  linkToActivate:
    "A link to activate your account has been sent to the e-mail address provided. After activation, you will be able to log into your account.",
};

const visitsView = {
  sheduleAnAppointment: "Shedule an appointment",
  visitAdded: "Visit added successfully",
  yourVisits: "Your visits",
  endedVisits: "Ended visits",
  noVisits: "There are no visits to display",
  backToVisitsList: "Back to visits list",
  service: "Service",
  expert: "Expert",
  visitHour: "Hour",
};

const servicesView = {
  services: "Services",
  noServices: "No services",
};

export default {
  buttons,
  form,
  loginView,
  registerView,
  resetPassword,
  visitsView,
  servicesView,
  ...common,
  ...auth,
};
