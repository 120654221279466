import React from "react";
import styled from "styled-components";

export interface IHamburgerMenuProps {
  isOpen: boolean;
  children?: React.ReactNode;
}

interface MenuProps {
  isVisible: boolean;
}

const Menu = styled.div<MenuProps>`
  touch-action: none;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0)" : "translateX(100%)"};
  background-color: ${({ theme }) => theme.palette.backgroundGuest};
  transition: all ease-in-out 0.2s;
  z-index: 3;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: inherit;
  justify-content: center;
  gap: 60px;
  width: 100%;
  align-items: center;
  padding-inline: 20px;
`;

const HamburgerMenu = ({ isOpen, children }: IHamburgerMenuProps) => {
  return (
    <Menu isVisible={isOpen}>
      <Container>{children}</Container>
    </Menu>
  );
};

export default HamburgerMenu;
