import { Select } from "components/_form";
import { Controller, useFormContext } from "react-hook-form";
import { IOption } from "components/_form/Select/Select";
import { FC, useCallback, useEffect, useState } from "react";
import { H4 } from "components/Typography";
import { ElementWrapper, Grid } from "../AddVisitView.styled";
import { useParams } from "react-router-dom";
import { getExperts } from "api/experts";
import { toast } from "react-toastify";
import { getUserFullName } from "utilities/user";
import { getServices } from "api/services";
import { IExpert } from "types/experts";
import { IService } from "types/services";

export interface IOptionExtended<T> extends IOption {
  data: T;
}

export const ExpertsSpecialists: FC = () => {
  const { serviceId, expertId } = useParams();
  const { control, watch, setValue } = useFormContext();
  const [expertOptions, setExpertOptions] = useState<
    IOptionExtended<IExpert>[]
  >([]);
  const [serviceOptions, setServiceOptions] = useState<
    IOptionExtended<IService>[]
  >([]);
  const [initialLoading, setInitialLoading] = useState({
    expert: false,
    service: false,
  });
  const service = watch("service");
  const expert = watch("expert");

  const fetchExperts = useCallback(async () => {
    try {
      const queryFilters = {
        ...(service && { service_ids: [service.data.id] }),
      };
      const response = await getExperts(queryFilters);
      if (response.status === 200) {
        const options = response.data.data.map((expert) => {
          return {
            value: expert.id.toString(),
            label: getUserFullName(expert.profile),
            data: expert,
          };
        });
        setExpertOptions(options);
        if (expertId && !initialLoading.expert) {
          const foundExpertOption = options.find(
            (option: IOption) => option.value === expertId,
          );
          if (foundExpertOption) setValue("expert", foundExpertOption);
          setInitialLoading((curr) => {
            return {
              expert: true,
              service: curr.service,
            };
          });
        }
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
    }
  }, [expertId, initialLoading.expert, setValue, service]);

  const fetchServices = useCallback(async () => {
    try {
      const queryFilters = {
        ...(expert && { user_ids: [expert.data.id] }),
      };
      const response = await getServices(queryFilters);
      if (response.status === 200) {
        const options = response.data.data.map((service) => {
          return {
            value: service.id.toString(),
            label: service.title,
            data: service,
          };
        });
        setServiceOptions(options);

        if (serviceId && !initialLoading.service) {
          const foundServiceOption = options.find(
            (serviceOption) => serviceOption.value === serviceId,
          );
          if (foundServiceOption) setValue("service", foundServiceOption);
          setInitialLoading((curr) => {
            return {
              expert: curr.expert,
              service: true,
            };
          });
        }
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
    }
  }, [expert, initialLoading.service, serviceId, setValue]);

  useEffect(() => {
    fetchExperts();
  }, [fetchExperts]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <ElementWrapper>
      <H4 variant="h4">Wybierz rodzaj usługi oraz specjalistę</H4>
      <Grid>
        <Controller
          control={control}
          name="service"
          render={({ field: { value, onChange } }) => (
            <Select
              label="Rodzaj usługi"
              options={serviceOptions}
              isMulti={false}
              selectedOptions={value}
              onChange={(val) => onChange(val)}
              isClearable
            />
          )}
        />
        <Controller
          control={control}
          name="expert"
          render={({ field: { value, onChange } }) => (
            <Select
              label="Specjalista"
              options={expertOptions}
              isMulti={false}
              selectedOptions={value}
              onChange={(val) => {
                onChange(val);
              }}
              isClearable
            />
          )}
        />
      </Grid>
    </ElementWrapper>
  );
};
