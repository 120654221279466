export const API_URL = process.env.REACT_APP_API_URL;
export const BLOB_URL = process.env.REACT_APP_BLOB_URL;

export const FAQS = `${API_URL}/helps`;

//services
export const SERVICES = `${API_URL}/services`;

//specialists
export const EXPERTS = `${API_URL}/experts`;

//patients
export const PATIENTS = `${API_URL}/patients`;

//regulations
export const REGULATIONS = `${API_URL}/regulations`;

//visits
export const VISITS = `${API_URL}/visits`;

//profile
export const PROFILE = `${API_URL}/profile`;

//knowledge-base enteries
export const ENTRIES = `${API_URL}/entries`;

//shedules
export const SCHEDULES = `${API_URL}/schedules`;
