import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

interface IFieldLabel {
  error?: string;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
  required?: boolean;
}

export const FieldLabel = styled.label<IFieldLabel>`
  ${({ theme, disabled, error, required }) => `
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    ${error && `color: ${theme.palette.accent.red}`};
    ${disabled && `color: ${rgba(theme.palette.text.primary, 0.4)}`};
    ${
      required &&
      `  ::after {
    content: " *";
    color: ${theme.palette.accent.red};
  }
    }`
    }
  `}
`;
