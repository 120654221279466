import { Flex } from "components/Flex";
import styled from "styled-components";

interface IMenuItem {
  active?: boolean;
}

export const Wrapper = styled(Flex)`
  align-items: center;
`;

export const Label = styled.span`
  font-size: 16px;
`;

export const MenuItem = styled(Flex)<IMenuItem>`
  position: relative;
  gap: 10px;
  height: 100%;
  ${({ theme, active }) => `
  color: ${active ? theme.palette.primary.main : theme.palette.text.primary};  
 ${active && `border-bottom: 2px solid ${theme.palette.primary.main};`};
    &:hover {          
      color: ${!active && theme.palette.primary.light};    
    }

  `}
  cursor: pointer;
`;
