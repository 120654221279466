import styled from "styled-components";

export const StyledToolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-weight: 600;
  align-items: center;
`;

export const ToolbarButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #ddd;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    svg > path {
      stroke: ${({ theme }) => theme.palette.neutral.white};
    }
  }
`;
