import { Card } from "components/Card";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import React, { CSSProperties, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { device } from "styles/theme";

const modalRoot = document.getElementById("modal-root")!;

export interface IModal {
  isOpen: boolean;
  onCancelClick: () => void;
  styles?: CSSProperties;
  children?: React.ReactNode;
}

const WrapperModal = styled.div`
  grid-template-columns: 60%;

  @media ${device.tablet} {
    grid-template-columns: 80%;
  }
  @media ${device.mobile} {
    grid-template-columns: 90%;
  }

  ${({ theme }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${rgba(theme.palette.neutral.dark, 0.3)};
  `}
`;

const StyledCard = styled(Card)`
  max-height: 100vh;
  overflow: auto;
  margin: 10px;
`;

export const Modal: React.FC<IModal> = ({
  isOpen,
  onCancelClick,
  styles,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => {
    onCancelClick();
  });

  return ReactDOM.createPortal(
    <>
      {isOpen ? (
        <WrapperModal style={styles}>
          <div ref={wrapperRef}>
            <StyledCard>{children}</StyledCard>
          </div>
        </WrapperModal>
      ) : null}
    </>,
    modalRoot
  );
};
