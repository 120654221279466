import { EULogos, Flex } from "components";
import { H3, H4, P } from "components/Typography";
import { useTranslation } from "react-i18next";

export const ProjectInfoView = () => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" width="100%" gap="20px">
      <H3 variant="h3">{t("infoViews.infoAboutProject")}</H3>
      <Flex flexDirection="column" width="100%" gap="20px">
        <H3 variant="h3" color="coloured" textAlign="center">
          Dermativa - Agata Adamczyk
          <br />
          realizuje projekt pn. <br />
          &quot;Sztuczna inteligencja w badaniach podologicznych dla
          usprawnienia procesów leczenia pacjentów&quot;
        </H3>
        <EULogos />
        <Flex flexDirection="column" width="100%" gap="10px">
          <H4 variant="h4">Cel projektu:</H4>
          <P variant="body">
            Celem projektu jest poprawa jakości usług i produktu poprzez
            wprowadzenie innowacyjnego rozwiązania do tworzenia wkładek
            ortopedycznych oraz umożliwienie monitorowania postępów terapii.
            Oczekiwane korzyści to skrócenie czasu usługi, podniesienie jakości
            opieki nad pacjentem, rozszerzenie oferty usług podologicznych,
            pozyskanie nowych pacjentów oraz wzmocnienie pozycji rynkowej
            poprzez wprowadzenie nowatorskich rozwiązań.
          </P>
        </Flex>
        <Flex flexDirection="column" width="100%" gap="10px">
          <H4 variant="h4">Efekt projektu:</H4>
          <P variant="body">
            Projekt unowocześni dotychczasowe metody produkcji wkładek
            ortopedycznych, badań podologicznych i monitorowania postępów
            terapii. Nowa metoda przyniesie liczne korzyści, w tym optymalizację
            procesu badania i formowania indywidualnych wkładek, poprawę jakości
            produktu oraz ułatwienie logistyki związanej z tymi badaniami.
            Dzięki innowacyjnym funkcjom i kompleksowej opiece nad pacjentami,
            projekt usprawni proces kontroli efektów terapii oraz pozwoli na
            przewidywanie skutków stosowania wkładek ortopedycznych. Nowoczesna
            aparatura dodatkowo podniesie atrakcyjność oferty.
          </P>
        </Flex>
        <Flex flexDirection="column" width="100%" gap="10px">
          <H4 variant="h4" textAlign="center">
            Efekt projektu: 293 930.55 PLN
          </H4>
          <H4 variant="h4" textAlign="center">
            Dofinansowanie projektu z UE: 249 840.96 PLN
          </H4>
          <H4 variant="h4" textAlign="center" color="coloured">
            Sfinansowano w ramach reakcji Unii na pandemię Covid-19
          </H4>
        </Flex>
      </Flex>
    </Flex>
  );
};
