import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import { AppLayout } from "layouts/AppLayout";
import { AuthLayout } from "layouts/AuthLayout";
import { Outlet, RouteObject } from "react-router-dom";
import { RegisterView } from "./views/RegisterView";
import { LoginView } from "./views/LoginView";
import { ResetPasswordView } from "./views/ResetPasswordView";
import { CustomView } from "./views/CustomView";
import { ContactView } from "views/info/ContactView";
import { FaqView } from "views/info/FaqView/FaqView";
import ServicesView from "views/ServicesView/ServicesView";
import SpecialistsView from "views/SpecialistsView/SpecialistsView";
import VisitsView from "views/VisitsView/VisitsView";
import { HomeView } from "views/HomeView/HomeView";
import { MyAccountView } from "views/MyAccountView/MyAccountView";
import { AddVisitView } from "views/AddVisitView/AddVisitView";
import { KnowledgeBaseView } from "views/KnowledgeBaseView/KnowledgeBaseView";
import { ArticleView } from "views/ArticleView";
import { PatientsView } from "views/PatientsView/PatientsView";
import { TherapyView } from "views/TherapyView/TherapyView";
import { PatientCardView } from "views/PatientCardView/PatientCardView";
import { VisitView } from "views/VisitView/VisitView";
import { VisitsCalendarView } from "views/VisitsCalendarView/VisitsCalendarView";
import { SpecialistsCalendarView } from "views/SpecialistsCalendarView/SpecialistsCalendarView";
import { ProjectInfoView } from "views/info/ProjectInfoView";
import { ExpertScheduleView } from "views/ExpertScheduleView/ExpertScheduleView";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AppLayout withInfoCard>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "/",
        element: <HomeView />,
      },
      {
        path: "/services",
        element: <ServicesView />,
      },
      {
        path: "/specialists",
        element: <SpecialistsView />,
      },
      {
        path: "/knowledge-base",
        element: <KnowledgeBaseView />,
      },
      {
        path: "/knowledge-base/:slug",
        element: <ArticleView />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    ),
    children: [
      {
        path: "/login",
        element: <LoginView />,
      },
      {
        path: "/register",
        element: <RegisterView />,
      },
      {
        path: "/password-reset",
        element: <ResetPasswordView />,
      },
    ],
  },
  {
    path: "/visits",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <VisitsView />,
      },
      {
        path: ":id",
        element: <VisitView />,
      },
      {
        path: "add",
        element: <AddVisitView />,
      },
      {
        path: "add/service/:serviceId",
        element: <AddVisitView />,
      },
      {
        path: "add/specialist/:expertId",
        element: <AddVisitView />,
      },
      {
        path: "add/service/:serviceId/specialist/:expertId",
        element: <AddVisitView />,
      },
    ],
  },
  {
    path: "/therapy",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <TherapyView />,
      },
    ],
  },
  {
    path: "/patients",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <PatientsView />,
      },
      {
        path: ":id",
        element: <PatientCardView />,
      },
    ],
  },

  {
    path: "/my-account",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <MyAccountView />,
      },
    ],
  },
  {
    path: "/visits-calendar",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <VisitsCalendarView />,
      },
    ],
  },
  {
    path: "/specialists-calendar",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <SpecialistsCalendarView />,
      },
    ],
  },
  {
    path: "/schedule",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <ExpertScheduleView />,
      },
    ],
  },
  {
    path: "/info",
    element: (
      <AppLayout withInfoCard>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "help",
        element: <FaqView />,
      },
      {
        path: "kontakt",
        element: <ContactView />,
      },
      {
        path: ":slug",
        element: <CustomView />,
      },
      {
        path: "project",
        element: <ProjectInfoView />,
      },
    ],
  },
];
