import request from "./index";
import { PATIENTS } from "constants/paths";
import qs from "qs";
import { IGetPatient, IPatients } from "types/patients";

interface IGetPatients {
  text?: string;
  page?: number;
  per_page?: number;
}

export const getPatients = (filters?: IGetPatients) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return request<IPatients>({
    options: {
      url: `${PATIENTS}?${query}`,
    },
  });
};

export const getPatient = (id?: number | string) => {
  return request<IGetPatient>({
    options: {
      url: `${PATIENTS}/${id}`,
    },
  });
};
