import { getVisits } from "api/visits";
import { Flex, Spinner } from "components";
import { P } from "components/Typography";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IVisit } from "types/visits";
import { AppointmentCard } from "./AppointmentCard";
import { useTranslation } from "react-i18next";

interface IVisitsList {
  variant: "upcoming" | "historical";
}

const variantMap = {
  upcoming: false,
  historical: true,
};

export const VisitsList = ({ variant }: IVisitsList) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [visits, setVisits] = useState<IVisit[]>([]);

  const handleVisits = useCallback(async () => {
    try {
      const response = await getVisits({ archived: variantMap[variant] });
      if (response.status === 200) {
        setVisits(response.data.data);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }, [variant]);

  useEffect(() => {
    handleVisits();
  }, [handleVisits]);

  return (
    <Flex flexDirection="column" width="100%" gap="10px">
      {isLoading ? (
        <Spinner />
      ) : !!visits.length ? (
        visits.map((e) => (
          <div key={e.id}>
            <AppointmentCard visit={e} />
          </div>
        ))
      ) : (
        <P variant="body" color="grey">
          {t("visitsView.noVisits")}
        </P>
      )}
    </Flex>
  );
};
