import { getEntries } from "api/entries";
import { Flex, Header, Pagination, Spinner } from "components";
import { H4, P } from "components/Typography";
import { BLOB_URL } from "constants/paths";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { device } from "styles/theme";
import { IEntry } from "types/entries";
import { ArticleAuthor } from "views/ArticleView";

const Wrapper = styled(Flex)`
  @media ${device.tablet} {
    flex-direction: column;
  }
  gap: 20px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
`;

const Image = styled(Flex)<{ image: string }>`
  width: 100%;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  min-height: 200px;
  @media ${device.laptop} {
    min-height: 150px;
  }
`;

const Title = styled(Flex)`
  width: 100%;
  min-width: 200px;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  padding: 20px 0px;
  @media ${device.tablet} {
    padding: 0px 20px 20px;
  }
`;

export const KnowledgeBaseView = () => {
  const [articles, setArticles] = useState<IEntry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const navigate = useNavigate();

  const handleEnteries = async () => {
    setIsLoading(true);
    try {
      const response = await getEntries();
      if (response.status === 200) {
        setArticles(response.data.data);
        setTotalPages(response.data.pagination?.pages ?? 0);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleEnteries();
  }, []);

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Header text={"Baza wiedzy"} />
      {isLoading ? (
        <Spinner />
      ) : articles.length > 0 ? (
        <>
          {articles.map((e) => (
            <Wrapper
              key={e.id}
              onClick={() => navigate(`/knowledge-base/${e.slug}`)}
            >
              <Image image={`${BLOB_URL + e.thumbnail}`} />

              <Title>
                <H4 variant="h4">{e.title}</H4>
                {ArticleAuthor}
              </Title>
            </Wrapper>
          ))}
          <Pagination
            current={currentPage}
            total={totalPages}
            setCurrent={setCurrentPage}
          />
        </>
      ) : (
        <P variant="body" color="grey">
          Brak artykułów do wyświetlenia
        </P>
      )}
    </Flex>
  );
};
