import { Flex } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const FormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  gap: 20px;
`;

export const ElementWrapper = styled(Flex)`
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  padding: 20px;
  border-radius: 10px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 20px;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  } ;
`;
