import { ToastContainer } from "react-toastify";
import { useRoutes } from "react-router-dom";
import { routes } from "routes";

import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "hooks/ScrollToTop";

export const App = () => {
  const appRoutes = useRoutes(routes);

  return (
    <div>
      <ScrollToTop />
      {appRoutes}
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default App;
