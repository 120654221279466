import { TabsMenu } from "components/TabsMenu/TabsMenu";
import { H3 } from "components/Typography";
import { FormProvider, useForm } from "react-hook-form";
import { FormWrapper, Grid } from "./AddVisitView.styled";
import { useCallback, useState } from "react";
import { ExpertsSpecialists } from "./components/ExpertsSpecialists";
import { PersonalData } from "./components/PersonalData";
import { Summary } from "./components/Summary";
import { DateAndHours } from "./components/DateAndHours";
import { Kind } from "./components/Kind";
import { IAddVisit, IPostVisitPayload } from "types/visits";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { postVisits } from "api/visits";

export const AddVisitView = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const { t } = useTranslation();

  const methods = useForm<IAddVisit>({
    mode: "onChange",
  });

  const onSubmit = useCallback(
    async (data: IAddVisit) => {
      try {
        const startDateAndHour = moment(data.date + " " + data.hour);
        const serializedData: { visit: IPostVisitPayload } = {
          visit: {
            kind: data.kind,
            patient_id: data.patient_id,
            expert_id: Number(data.expert.value),
            office_id: null,
            service_id: Number(data.service.value),
            start_at: startDateAndHour.toString(),
            finish_at: startDateAndHour
              .add(data.service.data.duration_in_minutes, "m")
              .toString(),
          },
        };

        await postVisits(serializedData);
        toast.success(t("visitsView.visitAdded"));
      } catch (err) {
        toast.error(t("somethingWentWrong"));
      }
    },
    [t],
  );

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <H3 variant="h3">Umów wizytę</H3>
        <TabsMenu
          tabs={[
            { id: 1, name: "Wizyta dla mnie " },
            { id: 2, name: "Chcę umówić wizytę dla kogoś" },
          ]}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
        <ExpertsSpecialists />
        <Grid>
          <PersonalData />
          <DateAndHours />
        </Grid>
        <Kind />
        <Summary />
      </FormWrapper>
    </FormProvider>
  );
};
