import { Flex, Header, Pagination, Spinner } from "components";
import { P } from "components/Typography";
import { TextField } from "components/_form";
import { useEffect, useState } from "react";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { PatientTile } from "./components/PatientTile";
import { IPatient } from "types/patients";
import { getPatients } from "api/patients";
import { toast } from "react-toastify";
import styled from "styled-components";
import { device } from "styles/theme";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding-bottom: 20px;
  grid-template-areas: "a b c";
  gap: 10px;
  @media ${device.tablet} {
    grid-template-areas:
      "a . c"
      "b b b";
  }

  > :nth-child(1) {
    grid-area: a;
  }
  > :nth-child(2) {
    grid-area: b;
  }
  > :nth-child(3) {
    grid-area: c;
  }
`;

export const PatientsView = () => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [phrase, setPhrase] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const handlePatients = async () => {
    setIsLoading(true);
    try {
      const response = await getPatients({
        text: phrase,
        page: currentPage,
        per_page: 5,
      });
      if (response.status === 200) {
        setPatients(response.data.data);
        setTotalPages(response.data.pagination?.pages ?? 0);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    handlePatients();
  }, [phrase]);

  useEffect(() => {
    handlePatients();
  }, [currentPage]);

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Grid>
        <Header text={"Pacjenci"} withoutMargin />
        <TextField
          placeholder="Zacznij piszać (Nazwisko, Mail, telefon)"
          icon={<Search />}
          value={phrase}
          onChange={(e) => setPhrase(e.target.value)}
        />
      </Grid>

      {isLoading ? (
        <Spinner />
      ) : patients.length > 0 ? (
        <>
          {patients.map((el) => (
            <PatientTile key={el.id} patient={el} />
          ))}
          <Pagination
            current={currentPage}
            total={totalPages}
            setCurrent={setCurrentPage}
          />
        </>
      ) : (
        <P variant="body" color="grey">
          Brak specialistów do wyświetlenia
        </P>
      )}
    </Flex>
  );
};
