import { Flex } from "components/Flex";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface ITabsMenu {
  tabs: { id: number; name: string }[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

const TabsContainer = styled(Flex)`
  width: 100%;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  gap: 40px;
`;

const Tab = styled(Flex)<{ isActive: boolean }>`
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  padding-block: 10px;
  ${({ isActive, theme }) =>
    isActive
      ? `color: ${theme.palette.primary.main}; border-bottom: 2px solid;`
      : ``};
`;

export const TabsMenu = ({ tabs, setSelectedTab, selectedTab }: ITabsMenu) => {
  return (
    <TabsContainer>
      {tabs.map((e) => (
        <Tab
          isActive={selectedTab === e.id}
          key={e.id}
          onClick={() => setSelectedTab(e.id)}
        >
          {e.name}
        </Tab>
      ))}
    </TabsContainer>
  );
};
