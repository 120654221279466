import { Box, Flex } from "components";
import { H4, P } from "components/Typography";
import { ReactNode } from "react";
import styled from "styled-components";

interface IHomeCard {
  card: {
    title: string;
    desc: string;
  };
  children?: ReactNode;
}

const Wrapper = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: start;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  width: 100%;
  gap: 20px;
`;

export const HomeCard = ({ card, children }: IHomeCard) => {
  return (
    <Wrapper>
      {children}
      <Flex
        justifyContent="space-between"
        width="100%"
        gap="20px"
        flexWrap="wrap"
      >
        <Box minWidth="165px">
          <H4 variant="h4">{card.title}</H4>
        </Box>

        <Box maxWidth="500px">
          <P variant="body">{card.desc}</P>
        </Box>
      </Flex>
    </Wrapper>
  );
};
