import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
});

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
  first_name: yup.string(),
  last_name: yup.string(),
  rules: yup.boolean().oneOf([true], "acceptRegulamin"),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
});

export const profileSchema = yup.object().shape({
  first_name: yup.string().required("fieldRequired"),
  last_name: yup.string().required("fieldRequired"),
  phone_number: yup.string().required("fieldRequired"),
});
