import { Box, Flex, Line } from "components";
import styled from "styled-components";
import { Button } from "components/_form";
import { H6, P } from "components/Typography";
import { IService } from "types/services";
import { useState } from "react";
import { ReactComponent as Down } from "assets/icons/chevron-down.svg";
import { ReactComponent as Up } from "assets/icons/chevron-up.svg";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useNavigate } from "react-router";

const Wrapper = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  width: 100%;
  padding: 20px;
`;

const ShowMore = styled(Flex)`
  cursor: pointer;
  gap: 10px;
  align-items: center; ;
`;

interface IServiceTile {
  serviceData: IService;
}

const ServiceTile = ({ serviceData }: IServiceTile) => {
  const [showDesc, setShowDesc] = useState(false);
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Flex flexDirection="column" gap="20px" width="100%">
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap="20px"
        >
          <Flex flexDirection="column" gap="5px">
            <H6 variant="h6">{serviceData.title}</H6>
            <ShowMore onClick={() => setShowDesc((prev) => !prev)}>
              <P variant="body">Dowiedz się więcej</P>
              {showDesc ? <Up /> : <Down />}
            </ShowMore>
          </Flex>
          <Flex flexDirection="column" gap="5px" flexGrow={1} alignItems="end">
            <H6 variant="h6" color="coloured">
              {serviceData.price} zł
            </H6>
            <P variant="body2" color="grey">
              {serviceData.duration_in_minutes} min
            </P>
          </Flex>

          <Button
            label="Umów wizytę"
            fullWidth={isMobile}
            onClick={() => navigate(`/visits/add/service/${serviceData.id}`)}
          />
        </Flex>
        {showDesc && (
          <>
            <Line />
            <P variant="body">{serviceData.description}</P>
          </>
        )}
      </Flex>
    </Wrapper>
  );
};

export default ServiceTile;
