import { rgba } from "utilities/rgba";

export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`,
};

export const theme = {
  palette: {
    primary: {
      main: "#006776",
      dark: "#003337",
      light: "#00a7b7",
    },
    secondary: {
      main: "#90CBC5",
      dark: "#65b7ae",
      light: "#bbdfdc",
    },
    accentColor: {
      main: "#98D4EB",
      dark: "#63bee1",
      light: "#cdeaf5",
    },
    neutral: {
      mainBlue: "#499CDD",
      dark: "#283038",
      grey: "#6A7482",
      lightGrey: "#E6E6E6",
      lightBlue: "#DFF2FB",
      light: "#EFEFEF",
      white: "#FFFFFF",
      veryLight: "#F6F6F6",
      medium: "#BEC9D5",
      green: "#74B281",
      darkGreen: "#1F7B76",
      veryDarkGreen: "#145c58",
      lightGreen: "#d4fffd",
    },
    accent: {
      yellow: "#F5D73F",
      orange: "#F5843F",
      lightBlue: "#26B9E7",
      blue: "#76b7e5",
      red: "#FB5C00",
      green: "#D09B34",
      lightGreen: "#FFF4DE",
    },
    text: {
      primary: "#333333",
      secondary: "#fff",
      grey: "#5C5C5C",
    },
    background: "#F5FBFD",
    backgroundGuest: "#E6F0F2",
  },
  textStyles: {
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    body: {
      fontSize: 14,
      lineHeight: "20px",
    },
    h6: {
      fontSize: 16,
      lineHeight: "22px",
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: 600,
    },
    h3: {
      fontSize: 24,
      lineHeight: "28px",
      fontWeight: 600,
    },
    h2: {
      fontSize: 34,
      lineHeight: "40px",
      fontWeight: 600,
    },
    h1: {
      fontSize: 48,
      lineHeight: "52px",
      fontWeight: 700,
    },
  },
};
