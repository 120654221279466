interface IUseAccount {
  isLogged: boolean;
  userData: TUserData;
  userRole: TRole;
}

type TUserData = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
  phone: string;
};

type TRole = "Patient" | "Expert" | "None";

export const useAccount = (): IUseAccount => {
  const currentUser = localStorage.getItem("currentUser");
  const isLogged = currentUser ? true : false;

  const getUserData = (): TUserData => {
    if (isLogged) {
      let data = JSON.parse(currentUser!);
      return {
        id: data.id ?? 0,
        email: data.email ?? "",
        firstName: data.profile?.first_name ?? "User",
        lastName: data.profile?.last_name ?? "",
        avatar: data.profile?.avatar ?? "",
        phone: data.profile?.phone_number ?? "",
      };
    } else {
      return {
        id: 0,
        email: "",
        firstName: "",
        lastName: "",
        avatar: "",
        phone: "",
      };
    }
  };

  const getUserRole = (): TRole => {
    if (isLogged) {
      let data = JSON.parse(currentUser!);
      return data.type;
    } else {
      return "None";
    }
  };

  const userData = getUserData();
  const userRole = getUserRole();

  return { isLogged, userData, userRole };
};
