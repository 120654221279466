import { postSchedule } from "api/shedules";
import { Box, Flex, Header } from "components";
import { H6 } from "components/Typography";
import { Button, TextField } from "components/_form";
import { UserContext } from "contexts/UserContext";
import moment from "moment";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

const hoursList = [
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
];

const daysList = [
  { value: "monday", label: "Poniedziałek" },
  { value: "tuesday", label: "Wtorek" },
  { value: "wednesday", label: "Środa" },
  { value: "thursday", label: "Czwartek" },
  { value: "friday", label: "Piątek" },
  { value: "saturday", label: "Sobota" },
  { value: "sunday", label: "Niedziela" },
];

const Pill = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.datk};
  color: ${({ theme }) => theme.palette.secondary.dark};
  padding: 10px 20px;
  width: 100px;
  ${({ selected, theme }) =>
    selected &&
    `color: white; font-weight: 600; background-color ${theme.palette.secondary.dark};`}
`;

export const ExpertScheduleView = () => {
  const { userData } = useContext(UserContext);
  const [date, setDate] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });
  const [hours, setHours] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });
  const [days, setDays] = useState<string[]>([]);

  const handleDays = (day: { value: string; label: string }) => {
    const tmp = days;
    if (tmp.find((e) => e === day.value)) {
      setDays(tmp.filter((e) => e !== day.value));
    } else {
      setDays([...tmp, day.value]);
    }
  };

  const handleSchedule = async () => {
    try {
      const preparedData = {
        schedule: {
          from_time: moment(date.start + " " + hours.start).format(),
          to_time: moment(date.end + " " + hours.end).format(),
          expert_id: userData.id,
          days,
        },
      };

      const response = await postSchedule(preparedData);
      if (response.status === 201) {
        toast.success("Harmonogram dostępności został dodany");
      } else {
        toast.error("Błąd, spróbuj ponownie");
      }
    } catch {
      toast.error("Błąd, spróbuj ponownie");
    }
  };

  return (
    <Flex flexDirection="column" gap="30px">
      <Header text={"Ustal dostępność"} />
      <Flex flexDirection="row" gap="10px">
        <TextField
          type="date"
          label="Data rozpoczęcia"
          onChange={(e) =>
            setDate((prev) => {
              return { ...prev, start: e.target.value };
            })
          }
          value={date?.start}
        />
        <TextField
          type="date"
          label="Data zakończenia"
          onChange={(e) =>
            setDate((prev) => {
              return { ...prev, end: e.target.value };
            })
          }
          value={date?.end}
        />
      </Flex>
      <Flex flexDirection="row" gap="30px">
        <Flex flexDirection="column" gap="10px">
          <H6 variant="h6">Godzina rozpoczęcia:</H6>
          {hoursList.map((e) => (
            <Pill
              key={e}
              selected={hours.start === e}
              onClick={() =>
                setHours((prev) => {
                  return { ...prev, start: e };
                })
              }
            >
              {e}
            </Pill>
          ))}
        </Flex>
        <Flex flexDirection="column" gap="10px">
          <H6 variant="h6">Godzina zakończenia:</H6>
          {hoursList.map((e) => (
            <Pill
              key={e}
              selected={hours.end === e}
              onClick={() =>
                setHours((prev) => {
                  return { ...prev, end: e };
                })
              }
            >
              {e}
            </Pill>
          ))}
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="10px">
        <H6 variant="h6">Dni tygodnia:</H6>
        <Flex flexDirection="row" gap="10px">
          {daysList.map((e: { value: string; label: string }) => (
            <Pill
              key={e.value}
              selected={!!days.find((day) => day === e.value)}
              onClick={() => handleDays(e)}
            >
              {e.label}
            </Pill>
          ))}
        </Flex>
      </Flex>
      <Box>
        <Button label="Dodaj" onClick={handleSchedule} />
      </Box>
    </Flex>
  );
};
