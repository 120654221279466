import request from "./index";
import { EXPERTS } from "constants/paths";
import qs from "qs";
import { IExperts } from "types/experts";

interface IGetExperts {
  text?: string;
  page?: number;
  per_page?: number;
  service_ids?: number[];
}

export const getExperts = (filters?: IGetExperts) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return request<IExperts>({
    options: {
      url: `${EXPERTS}?${query}`,
    },
  });
};
