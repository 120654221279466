import { IPage, IPageRes } from "types/pages";
import request from "./index";

export const getPages = () =>
  request<IPageRes>({
    options: {
      url: "/pages",
      method: "get",
    },
  });

export const getPage = (id: string) =>
  request<{ data: IPage }>({
    options: {
      url: `/pages/${id}`,
      method: "get",
    },
  });
