import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface ILink {
  to: LinkProps["to"];
  target?: string;
  children?: React.ReactNode;
}

const StyledLink = styled(RouterLink)`
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
  ${({ theme }) => `
    font-size: 14px;
    color: ${theme.palette.text.primary}
  `};
`;

const Link: React.FC<ILink> = ({ children, to, target }) => (
  <StyledLink target={target} to={to}>
    {children}
  </StyledLink>
);

export { Link };
