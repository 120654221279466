import { logout } from "api/authApi";
import { Flex } from "components";
import { sidebarItems } from "constants/sidebarItems";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LogoutIcon } from "assets/icons/nav-logout.svg";
import { UserContext } from "contexts/UserContext";
import { useContext } from "react";

const Item = styled(Flex)<{ active?: boolean }>`
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  align-items: center;
  width: 180px;
  gap: 10px;
  ${({ active, theme }) =>
    active
      ? `background-color:${theme.palette.primary.main}; color: ${theme.palette.text.secondary}; svg path {stroke: ${theme.palette.text.secondary};};`
      : `color: ${theme.palette.text.primary}; svg path {stroke: ${theme.palette.text.primary};};  :hover {
    background-color: ${theme.palette.backgroundGuest};
  }`}
  svg {
    flex-shrink: 0;
  }
`;

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.status === 204) navigate("/login");
    } catch (e: any) {
    } finally {
    }
  };

  return (
    <Flex flexDirection="column" gap="10px">
      {sidebarItems
        .filter((e) => e.role.find((el) => el === userRole))
        .map((e, k) => (
          <Item
            key={k}
            onClick={() => navigate(e.path)}
            active={location.pathname.includes(e.path)}
          >
            {e.icon} {e.text}
          </Item>
        ))}
      <Item onClick={() => handleLogout()}>
        <LogoutIcon />
        Wyloguj się
      </Item>
    </Flex>
  );
};
