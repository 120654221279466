import { PROFILE } from "constants/paths";
import { IProfile, IUser } from "types/users";
import request from "./index";
import { serialize } from "object-to-formdata";

export const updateProfile = (profile: IProfile) =>
  request<{ data: IUser }>({
    options: {
      url: PROFILE,
      method: "patch",
      headers: {
        Accept: "application/json",
      },
      data: serialize(profile),
    },
  });
