import MDEditor from "@uiw/react-md-editor";
import { getPage } from "api/pages";
import { Flex, Header } from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IPage } from "types/pages";

export const CustomView = () => {
  const [content, setContent] = useState<IPage>({});
  const { slug } = useParams<{ slug: string }>();

  const getContent = async () => {
    if (slug) {
      const { data } = await getPage(slug);
      setContent(data?.data);
    }
  };

  useEffect(() => {
    getContent();
  }, [slug]);

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Header text={content.name} />
      <MDEditor.Markdown
        style={{ padding: 10 }}
        source={content.content}
        linkTarget="_blank"
      />
    </Flex>
  );
};
