const common = {
  privacyPolicy: "polityka prywatności",
  regulamin: "regulamin",
  first_name: "imię",
  last_name: "nazwisko",
  somethingWentWrong: "Coś poszło nie tak",
};

const auth = {
  login: "zaloguj się",
  register: "zarejestruj się",
  password: "hasło",
  forgotPass: "Zapomniałeś hasła?",
  emailRequired: "Email jest wymagany",
  badEmailFormat: "Niepoprawny format email",
  passwordRequired: "Hasło jest wymagane",
  passMinVal: "Hasło powinno zawierać przynajmniej 6 znaków",
  passMaxVal: "Hasło może zawierać maksymalnie 18 znaków",
  passDiffErr: "Hasła nie są takie same",
  fieldRequired: "Pole jest wymagane",
  acceptRegulamin: "Zaakceptuj regulamin",
  password_confirmation: "potwierdzenie hasła",
  send_email: "Wyślij e-mail",
};

const buttons = {
  register: "Zarejestruj się",
  login: "Zaloguj się",
  logout: "Wyloguj się",
  remindPassword: "Przypomnij hasło",
  sheduleAnAppointment: "Umów wizytę",
  visitDetails: "Szczegóły wizyty",
  sheduleAnAppointmentAgain: "Umów ponownie",
};

const form = {
  email: {
    label: "Email:",
    placeholder: "email@email.com",
  },
  password: {
    label: "Hasło:",
    placeholder: "********",
  },
  confirmPassword: {
    label: "Powtórz hasło:",
    placeholder: "********",
  },
  firstName: {
    label: "Imię:",
    placeholder: "Jan",
  },
  lastName: {
    label: "Nazwisko:",
    placeholder: "Kowalski",
  },
};

const loginView = {
  login: "Zaloguj się",
  rememberMe: "Zapamiętaj mnie",
  remindPassword: "Przypomnij hasło",
  accountless: "Nie masz konta?",
};

const resetPassword = {
  forgotMyPassword: "Zapomniałem hasła",
  writeEmail:
    "Wpisz adres e-mail powiązany z kontem, a my wyślemy Ci link potrzebny do zresetowania hasła.",
  checkYourMailbox: "Sprawdź skrzynkę pocztową",
  linkToSet:
    "Na podany adres E-mail został wysłany link umożliwiający ustawienie nowego hasła.",
};

const registerView = {
  register: "Zarejestruj się",
  accountAllready: "Masz już konto?",
  linkToActivate:
    "Na podany adres E-mail został wysłany link umożliwiający aktywację Twojego konta. Po aktywacji będzie można zalogować się na konto.",
};

const homeView = {
  podiatryOffice: "Gabinet podologiczny Dermativa",
  cards: [
    {
      title: "Diagnostyka stóp",
      desc: "Skuteczna terapia to precyzyjnie dobrana terapia. Stosując lej diagnostyczny po nitce do kłębka docieramy do przyczyny problemu.",
    },
    {
      title: "Badania",
      desc: "W trosce o szybką i skuteczną terapię w Dermativa wykonywane są badania mykologiczne i bakteryjne.",
    },
    {
      title: "Innowacyjność",
      desc: "Zaawansowane technologie pozwalają na wdrażanie skutecznych terapii oraz precyzyjną diagnozę problemu. Ciągłe podnoszenie kwalifikacji jest nieodłącznym elementem naszej pracy.",
    },
    {
      title: "Bezpieczeństwo",
      desc: "Twoje bezpieczeństwo jest priorytetem! Narzędzia są dezynfekowane i sterylizowane zgodnie z zaleceniami Polskiego Stowarzyszenia Sterylizacji Medycznej.",
    },
  ],
};

const visitsView = {
  sheduleAnAppointment: "Umów wizytę",
  visitAdded: "Wizyta została dodana",
  yourVisits: "Twoje wizyty",
  endedVisits: "Zakończone wizyty",
  noVisits: "Brak wizyt do wyświetlenia",
  backToVisitsList: "Wróć do listy wizyt",
  service: "Usługa",
  expert: "Specialista",
  visitHour: "Godzina",
  meetingType: "Typ spotkania",
  dateOfVisit: "Data wizyty",
  hour: "godzina",
  visitPaymentStatus: "Status płatności za wizytę",
  description: "Opis badania",
  paid: "Opłacona",
  unPaid: "Niepłacona",
  chatWithExpert: "Chat ze specjalistą",
  chatWithPatient: "Chat z pacjentem",
  writeSomething: "Napisz coś, aby rozpocząć rozmowę",
  chatUnavailable: "Przepraszamy. Chat niedostępny",
  startWriting: "Zacznij pisać...",
  visitsCalendar: "Kalendarz wizyt",
  specialistsCalendar: "Kalenarz specjalistów",
  chooseFromCalendar: "Wybierz z kalendarza",
};

const servicesView = {
  services: "Usługi",
  noServices: "Brak usług do wyświeltenia",
};

const infoViews = {
  infoAboutProject: "Informacje o projekcie",
};

export default {
  buttons,
  form,
  loginView,
  registerView,
  resetPassword,
  homeView,
  visitsView,
  servicesView,
  infoViews,
  ...common,
  ...auth,
};
