import React from "react";
import styled from "styled-components";
import {Box, Card} from "components";
import {Flex} from "components/Flex";
import {P} from "components/Typography/Typography";
import { useDropdown } from "hooks/useDropdown";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "assets/icons/chevron-up.svg";

export interface IDropdown {
  items: Array<{
    label: string;
    onClick: () => void;
  }>;
  children?: React.ReactNode;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled(Flex)`
  cursor: pointer;
`;

const WrapperDropdown = styled.div`
  ${({ theme }) => `
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: 16px;
    min-width: 180px;
    z-index: 1000;
  `}
`;

const StyledTypography = styled(P)`
  ${({ theme }) => `
    padding: 12px 0px;
    white-space: nowrap;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: ${theme.palette.neutral.lightBlue};
    }
  `}
`;

export const Dropdown: React.FC<IDropdown> = ({ items, children }) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren
        ref={buttonRef}
        role="button"
        aria-hidden="true"
        alignItems="center"
      >
        <Box mr={3}>{isVisible ? <ChevronUp /> : <ChevronDown />}</Box>
        {children}
      </WrapperChildren>

      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Card p={3}>
              {items.map(({ label, onClick }) => (
                <div
                  key={label}
                  onClick={handleClick(onClick)}
                  aria-hidden="true"
                  role="button"
                >
                  <StyledTypography variant="body" color="grey">
                    {label}
                  </StyledTypography>
                </div>
              ))}
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
