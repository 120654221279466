import { getVisit } from "api/visits";
import { BackButton, Chat, Flex, Line, Spinner } from "components";
import { H6, P } from "components/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IVisit } from "types/visits";
import {
  ChatMobile,
  ChatWrapper,
  Grid,
  GridText,
  Wrapper,
} from "./VisitView.styled";
import { getUserFullName } from "utilities/user";
import { format } from "date-fns";
import { useWindowWidth } from "hooks/useWindowWidth";
import { Button } from "components/_form";
import { ReactComponent as SendIcon } from "assets/icons/send-icon.svg";
import { UserContext } from "contexts/UserContext";

export const VisitView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [visit, setVisit] = useState<IVisit>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMobileChat, setShowMobileChat] = useState<boolean>(false);
  const { isMobile } = useWindowWidth();
  const { userRole } = useContext(UserContext);

  const handleVisit = useCallback(async (id: number | string) => {
    setIsLoading(true);
    try {
      const response = await getVisit(id);
      if (response.status === 200) {
        setVisit(response.data.data);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!!id) handleVisit(id);
  }, [handleVisit, id]);

  const chat = (
    <Chat
      expert={{
        id: Number(visit?.expert.id),
        name: getUserFullName(visit?.expert.profile),
        avatar: visit?.expert.profile?.avatar ?? "",
      }}
      patient={{
        id: Number(visit?.patient.id),
        name: getUserFullName(visit?.patient.profile),
        avatar: visit?.patient.profile?.avatar ?? "",
      }}
      onCancel={() => setShowMobileChat(false)}
    />
  );

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex flexDirection="column" gap="20px" width="100%">
          <BackButton text={t("visitsView.backToVisitsList")} />
          <Grid>
            <Wrapper>
              <H6 variant="h6">{visit?.service.title}</H6>
              <P variant="body2" color="coloured">
                {t("visitsView.expert")}:{" "}
                {getUserFullName(visit?.expert.profile)}
              </P>
              <GridText>
                <P variant="body2">{t("visitsView.meetingType")}:</P>
                <P variant="body2">{visit?.kind}</P>
                <P variant="body2">{t("visitsView.dateOfVisit")}:</P>
                <P variant="body2">
                  {format(new Date(visit?.start_at ?? ""), "d MMMM yyyy")},
                  {t("visitsView.hour")}{" "}
                  {format(new Date(visit?.start_at ?? ""), "H:mm")}
                </P>
                <P variant="body2">{t("visitsView.visitPaymentStatus")}:</P>
                <P variant="body2">
                  {visit?.paid ? t("visitsView.paid") : t("visitsView.unPaid")}
                </P>
              </GridText>
              <Line />
              <P variant="body2" fontWeight={600}>
                {t("visitsView.description")}:
              </P>
              <P variant="body2">{visit?.service.description}</P>
            </Wrapper>
            {isMobile ? (
              <Button
                outlined
                size="lg"
                onClick={() => setShowMobileChat(true)}
              >
                <SendIcon />
                {userRole === "Expert"
                  ? t("visitsView.chatWithPatient")
                  : t("visitsView.chatWithExpert")}
              </Button>
            ) : (
              <ChatWrapper>{chat}</ChatWrapper>
            )}
          </Grid>
        </Flex>
      )}
      {isMobile && <ChatMobile isVisible={showMobileChat}>{chat}</ChatMobile>}
    </>
  );
};
