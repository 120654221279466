import React from "react";
import styled from "styled-components";
import { Flex, Link } from "components";
import { P } from "components/Typography/Typography";
import { theme } from "styles";
import { device } from "styles/theme";

interface IFooter {
  variant: Variant;
}

type Variant = "white" | "color";

const backgroundVariant = {
  white: theme.palette.neutral.white,
  color: theme.palette.background,
};

const Wrapper = styled(Flex)<{ variant: Variant }>`
  z-index: 3;

  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-block: 10px;
  padding-inline: 160px;
  @media ${device.desktop} {
    padding-inline: 80px;
  }
  @media ${device.laptop} {
    padding-inline: 40px;
  }
  @media ${device.tablet} {
    padding-inline: 10px;
  }
  ${({ variant }) => `background-color: ${backgroundVariant[variant]};`};
  a {
    text-decoration: none;
  }
`;

const VerticalLine = styled.div`
  height: 16px;
  width: 1px;
  ${({ theme }) => `background-color: ${theme.palette.neutral.white}`}
`;

const GappedFlex = styled(Flex)`
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
`;

export const Footer = ({ variant }: IFooter) => {
  const year = new Date().getFullYear();
  return (
    <Wrapper width="100%" height="100%" alignItems="start" variant={variant}>
      <P variant="body">©{year} - DERMATIVA - Wszystkie prawa zastrzeżone.</P>
      <GappedFlex>
        <Link to="/info/polityka-prywatnosci">
          <P variant="body">Polityka Prywatności</P>
        </Link>
        <VerticalLine />
        <Link to="/info/regulamin">
          <P variant="body">Regulamin strony</P>
        </Link>
      </GappedFlex>
    </Wrapper>
  );
};
