import { FC } from "react";
import { H1 } from "components/Typography/Typography";
import { Flex, Box } from "components";
import { DotLine } from "components/DotLine";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as LocationIcon } from "assets/icons/ico-location.svg";
import { ReactComponent as MailIcon } from "assets/icons/ico-mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/ico-phone.svg";
import { GoogleMap } from "components/GoogleMap/GoogleMap";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Failed to load google map due to react strict mode</p>
      <a href="https://github.com/google-map-react/google-map-react/issues/1116#issuecomment-1150589667">
        More about issue
      </a>
    </div>
  );
}

const StyledImg = styled.div`
  object-fit: contain;
  border-radius: 15px;
  width: 450px;
  height: 285px;
`;

const Icon = styled.div`
  ${({ theme }) => `    
    background: ${theme.palette.neutral.funBlue};
  `}
  width: 45px;
  height: 45px;
  border-radius: 50px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  }
`;

export const ContactView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex width="70%" margin="0 auto" flexDirection="column">
      <H1 mb={1} variant="h1">
        Kontakt
      </H1>
      <DotLine my={4} />
      <Flex flexDirection="row" alignItems="center">
        <StyledImg />
        <Flex flexDirection="column" ml={3}>
          <Flex alignItems="center">
            <Box m={2}>
              <Icon>
                <LocationIcon />
              </Icon>
            </Box>
            ul. Lorem Ipsum
          </Flex>
          <Flex alignItems="center">
            <Box m={2}>
              <Icon>
                <MailIcon />
              </Icon>
            </Box>
            kontakt@mail.pl
          </Flex>
          <Flex alignItems="center">
            <Box m={2}>
              <Icon>
                <PhoneIcon />
              </Icon>
            </Box>
            111 111 111
          </Flex>
        </Flex>
      </Flex>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <GoogleMap lat={59} lng={17} />
      </ErrorBoundary>
    </Flex>
  );
};
