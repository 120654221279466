import React from "react";
import { Flex } from "components";
import { PulseLoader } from "react-spinners";
import { theme } from "styles";

export const Spinner = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="36px"
    >
      <PulseLoader color={theme.palette.primary.main} />
    </Flex>
  );
};
