import React, { FC, useContext, useState } from "react";
import { Avatar, Flex, Menu } from "components";
import styled from "styled-components";
import { navItems } from "constants/navItems";
import DermativaLogo from "assets/images/logo-navbar.png";
import { Button } from "components/_form";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "hooks/useWindowWidth";
import { ReactComponent as Hamburger } from "assets/icons/hamburger-icon.svg";
import { ReactComponent as X } from "assets/icons/x-icon.svg";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { logout } from "api/authApi";
import { device } from "styles/theme";
import { P } from "components/Typography";
import HamburgerMenu from "./HamburgerMenu";
import { UserContext } from "contexts/UserContext";

const Wrapper = styled(Flex)`
  position: sticky;
  top: 0;
  width: 100%;
  padding-block: 10px;
  padding-inline: 160px;
  @media ${device.desktop} {
    padding-inline: 80px;
  }
  @media ${device.laptop} {
    padding-inline: 40px;
  }
  @media ${device.tablet} {
    padding-inline: 10px;
  }
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  background-color: ${({ theme }) => theme.palette.neutral.white};
`;

const Logo = styled.img`
  margin-bottom: 5px;
  height: 35px;
  width: auto;
  cursor: pointer;
`;

const UserButton = styled(Flex)<{ isMobile: boolean }>`
  gap: 10px;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.backgroundGuest};
  cursor: pointer;
  padding: 10px;
  height: 39px;
  justify-content: center;
  ${({ isMobile, theme }) =>
    isMobile
      ? `width: 100%; box-shadow: inset 0px 0px 0px 1px;color: ${theme.palette.primary.main};`
      : `width: min-content`};
`;

export const Navbar: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { isLogged, userRole } = useAccount();
  const { userData } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.status === 204) navigate("/login");
    } catch (e: any) {
    } finally {
    }
  };

  const guestButtons = (
    <>
      <Button
        label={t("buttons.login")}
        fullWidth={isMobile}
        outlined
        onClick={() => {
          setIsOpen(false);
          navigate("/login");
        }}
      />
      <Button
        label={t("buttons.sheduleAnAppointment")}
        fullWidth={isMobile}
        onClick={() => {
          setIsOpen(false);
          navigate("/visits/add");
        }}
      />
    </>
  );

  const userButtons = (
    <>
      <UserButton
        onClick={() => {
          setIsOpen(false);
          navigate("/my-account");
        }}
        isMobile={isMobile}
      >
        <Avatar size="small" url={userData.avatar} />
        <P variant="body" fontWeight={600} color="coloured">
          {userData.firstName} {userData.lastName}
        </P>
      </UserButton>

      {userRole !== "Expert" && (
        <Button
          fullWidth={isMobile}
          label={t("buttons.sheduleAnAppointment")}
          onClick={() => {
            navigate("/visits/add");
          }}
        />
      )}
    </>
  );

  return (
    <>
      <Wrapper
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Logo
          alt="Logo"
          src={DermativaLogo}
          onClick={() => {
            setIsOpen(false);
            navigate("/");
          }}
        />
        {isMobile ? (
          <Button
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            icon={isOpen ? <X /> : <Hamburger />}
          />
        ) : (
          <Flex gap="10px" alignItems="center">
            <Menu view="vertical" items={navItems} />
            {isLogged ? userButtons : guestButtons}
          </Flex>
        )}
      </Wrapper>
      {isMobile && (
        <HamburgerMenu isOpen={isOpen}>
          <Menu
            view="horizontal"
            items={navItems}
            onItemClick={() => setIsOpen(false)}
          />
          <Flex flexDirection="column" width="100%" gap="20px">
            {isLogged ? userButtons : guestButtons}
            {isLogged && (
              <Button
                outlined
                fullWidth={isMobile}
                label={t("buttons.logout")}
                onClick={() => {
                  handleLogout();
                }}
              />
            )}
          </Flex>
        </HamburgerMenu>
      )}
    </>
  );
};
