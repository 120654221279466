import { H3, P } from "components/Typography";
import { MyCalendar, Grid, Flex } from "components";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { ToolbarProps } from "react-big-calendar";
import {
  StyledToolbar,
  ToolbarButton,
  NavigationWrapper,
} from "./SpecialistsCalendarView.styled";
import { ReactComponent as BackIcon } from "assets/icons/chevron-left.svg";
import { getExperts } from "api/experts";
import { getUserFullName } from "utilities/user";
import { IEvent, IResource } from "components/Calendar/Calendar";
import { toast } from "react-toastify";
import { ReactComponent as Calendar } from "assets/icons/nav-calendar.svg";
import { getVisits } from "api/visits";
import { useTranslation } from "react-i18next";

export const SpecialistsCalendarView = () => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState<moment.Moment>(
    moment(new Date()),
  );
  const [resources, setResources] = useState<IResource[]>([]);
  const [events, setEvents] = useState<IEvent[]>([]);

  const fetchExperts = useCallback(async () => {
    try {
      const response = await getExperts({});
      if (response.status === 200) {
        let tmp: IResource[] = [];
        response.data.data.map((e) => {
          tmp.push({ resourceId: e.id, name: getUserFullName(e.profile) });
        });
        setResources(tmp);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, []);

  const fetchAppoitments = useCallback(() => {
    resources.forEach(async (e) => {
      try {
        const response = await getVisits({ expert_id: e.resourceId });
        if (response.status === 200) {
          let tmp: IEvent[] = [];
          response.data.data.map((e) => {
            tmp.push({
              id: e.id,
              fullName: getUserFullName(e.patient.profile),
              serviceType: e.service.title,
              start: new Date(e.start_at),
              end: new Date(e.finish_at),
              resourceId: e.expert.id,
            });
            const merged = events.concat(tmp);
            setEvents(merged);
          });
        } else {
          toast.error(response.statusText);
          return;
        }
      } catch (e: any) {
        toast.error(e.message);
      }
    });
  }, []);

  useEffect(() => {
    fetchExperts();
  }, [fetchExperts]);

  useEffect(() => {
    if (resources.length > 0) fetchAppoitments();
  }, [resources, fetchAppoitments]);

  useEffect(() => {
    setEvents([]);
    fetchAppoitments();
  }, [currentDate, fetchAppoitments]);

  const Toolbar = ({ date }: ToolbarProps) => (
    <StyledToolbar>
      <NavigationWrapper>
        <ToolbarButton
          onClick={() => {
            setCurrentDate(moment(date).subtract(1, "day"));
          }}
        >
          <BackIcon />
        </ToolbarButton>
        <P variant="body" fontWeight={600}>
          {moment(date).format("DD MMMM yyyy")}
        </P>
        <ToolbarButton
          onClick={() => {
            setCurrentDate(moment(date).add(1, "day"));
          }}
        >
          <BackIcon transform="rotate(180)" />
        </ToolbarButton>
      </NavigationWrapper>
      <label htmlFor="date">
        <Flex
          padding="20px 10px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          <Calendar />
          <P variant="body" fontWeight={600}>
            {t("visitsView.chooseFromCalendar")}:
          </P>
          <input
            type="text"
            onFocus={(e) => (e.target.type = "date")}
            id="date"
            value={moment(date).format("DD.MM.yyyy")}
            onChange={(e) => setCurrentDate(moment(e.target.value))}
          />
        </Flex>
      </label>
    </StyledToolbar>
  );

  return (
    <Grid gridTemplateColumns="1fr" width="100%" gap="10px">
      <H3 variant="h3">{t("visitsView.specialistsCalendar")}</H3>
      <MyCalendar
        defaultView="day"
        toolbarContent={Toolbar}
        date={currentDate.toDate()}
        views={["day"]}
        resources={resources}
      />
    </Grid>
  );
};
