import { H4, P } from "components/Typography";
import { ElementWrapper } from "../AddVisitView.styled";
import styled from "styled-components";
import { Flex } from "components";
import { Button } from "components/_form";
import { IAddVisit } from "types/visits";
import { useFormContext } from "react-hook-form";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  align-items: end;
`;

const ExtraInfo = styled(Flex)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.backgroundGuest};
  padding: 20px;
  border-radius: 10px;
`;

const kindMap = {
  online: "Online",
  telephone: "Teleporada",
  in_person: "Stacjonarnie",
};

export const Summary = () => {
  const { watch } = useFormContext<IAddVisit>();

  return (
    <>
      <ElementWrapper>
        <H4 variant="h4">Podsumowanie</H4>
        <Grid>
          <Flex flexDirection="column" gap="10px">
            <P variant="body2" fontWeight={600} color="grey">
              Usługa: {watch("service.label") ?? "-"}
            </P>
            <P variant="body2" fontWeight={600} color="grey">
              Specjalista: {watch("expert.label") ?? "-"}
            </P>
            <P variant="body2" fontWeight={600} color="grey">
              Forma: {kindMap[watch("kind")] ?? "-"}
            </P>
          </Flex>
          <Flex flexDirection="column" gap="10px">
            <P variant="body2" fontWeight={600} color="grey">
              Data: {watch("date") ?? "-"}
            </P>
            <P variant="body2" fontWeight={600} color="grey">
              Godzina: {watch("hour") ?? "-"}
            </P>
            <P variant="body2" color="coloured" fontWeight={600}>
              Cena: {watch("service.data.price") ?? "-"} zł
            </P>
          </Flex>
          <Button label="Zamów i zapłać" size={"sm"} type="submit" />
        </Grid>
      </ElementWrapper>
      <ExtraInfo>
        <P variant="body" color="coloured">
          Uwaga: Jeśli w ciągu 15 minut od rezerwacji, płatność nie zostanie
          zaksięgowana wizyta zostanie anulowana.
        </P>
      </ExtraInfo>
    </>
  );
};
