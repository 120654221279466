import { H4 } from "components/Typography";
import { TextField } from "components/_form";
import { ElementWrapper } from "../AddVisitView.styled";
import { useFormContext } from "react-hook-form";
import { VisitHours } from "./VisitHours";
import React from "react";
import { IAddVisit } from "types/visits";

export const DateAndHours: React.FC = () => {
  const { register } = useFormContext<IAddVisit>();

  return (
    <ElementWrapper>
      <H4 variant="h4">Termin wizyty</H4>
      <TextField type="date" label="Data:" {...register("date")} />
      <VisitHours />
    </ElementWrapper>
  );
};
