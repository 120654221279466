import { FC, useState } from "react";
import { Box, Card, Flex, Spinner } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "validation/authSchemas";
import { IResetPassword } from "types/forms/auth";
import { resetPassword } from "../api/authApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H4, P } from "components/Typography";

export const ResetPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [wasSent, setWasSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IResetPassword>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await resetPassword(data);
      if (response.status === 201) {
        setWasSent(true);
      }
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Card showBackButton onBackClick={() => navigate(-1)}>
      <form onSubmit={onSubmit}>
        <Flex
          flexDirection="column"
          gap="20px"
          width="100%"
          paddingX={["0px", "40px", "80px"]}
        >
          {!wasSent ? (
            <>
              <H4 variant="h4" textAlign="center">
                {t("resetPassword.forgotMyPassword")}
              </H4>
              <P variant="body" textAlign="center">
                {t("resetPassword.writeEmail")}
              </P>

              <TextField
                type="email"
                label={t("form.email.label")}
                placeholder={t("form.email.placeholder")}
                required
                {...register("email")}
                error={t(errors.email?.message || "")}
              />

              {isLoading ? (
                <Spinner />
              ) : (
                <Button type="submit" disabled={!formState.isValid}>
                  {t("buttons.remindPassword")}
                </Button>
              )}
            </>
          ) : (
            <>
              <H4 variant="h4" textAlign="center">
                {t("resetPassword.checkYourMailbox")}
              </H4>
              <P variant="body" textAlign="center">
                {t("resetPassword.linkToSet")}
              </P>
              <Button onClick={() => navigate("/login")}>
                {t("buttons.login")}
              </Button>
            </>
          )}
        </Flex>
      </form>
    </Card>
  );
};
