import GoogleMapReact from "google-map-react";

const Marker = ({ lat, lng }: { lat: number; lng: number }) => <div />;

export function GoogleMap({ lat, lng }: { lat: number; lng: number }) {
  const zoom = 14;

  return (
    <div style={{ height: "440px", width: "100%", marginTop: "30px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GOOGLE_MAP_API_KEY}` }}
        defaultCenter={{ lat, lng }}
        defaultZoom={zoom}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
}
