import { Flex } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const Wrapper = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 20px 30px;
  @media ${device.tablet} {
    padding: 20px 10px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  gap: 20px;
`;

export const GridText = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  gap: 12px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ChatWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
`;

interface ChatMobileProps {
  isVisible: boolean;
}

export const ChatMobile = styled.div<ChatMobileProps>`
  touch-action: none;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0)" : "translateX(100%)"};
  background-color: ${({ theme }) => theme.palette.neutral.white};
  transition: all ease-in-out 0.2s;
  z-index: 4;
`;
